import React from 'react';
import { Link } from 'react-router-dom';
import { theme } from '../Theme';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import t from '@jetshop/intl';
import striptags from 'striptags';

const CategoryItem = styled('div')`
  padding: 10px;
  position: relative;
  width: 33.3%;
`;

const CategoryContent = styled('div')`
  position: absolute;
  top: 100%;
  z-index: 1;
  padding: 0 10px;
  transform: translateY(-45px);
  transition: all, 0.2s ease;

  h2 {
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 1.5rem;
    font-family: ${theme.fonts.secondary};
    color: white;
    ${theme.below.md} {
      font-size: 1rem;
    }
  }
  span {
    margin-bottom: 20px;
    display: block;
  }
  ${theme.below.md} {
    & > div {
      display: none;
    }
  }
`;

const CategoryLink = styled(Link)`
  color: white;
  display: block;
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    background: linear-gradient(
      0deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(0, 0, 0, 1) 1%,
      rgba(0, 0, 0, 0) 50%
    );
    transform: translateY(30px);
    transition: all, 0.2s ease;
  }
  ${theme.below.md} {
  }

  &:hover {
    ${CategoryContent} {
      transform: translateY(-100%);
    }
    &:after {
      transform: translateY(0);
    }
  }
`;

const CategoryHiddenContent = styled('div')``;
const CallToAction = styled('button')`
  background: transparent;
  border: 1px solid white;
  color: white;
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 3px;
  margin-bottom: 20px;
  font-weight: normal;
  letter-spacing: 1px;
  font-family: ${theme.fonts.primary};

  transition: all, 0.2s ease;

  &:hover {
    color: black;
    background: white;
  }
`;

const BlogCard = ({ post }) => {
  if (!post || !post?.primaryRoute?.path) return null;
  var content = striptags(post.content.substr(0, 100) + '...');
  return (
    <CategoryItem className="blog-post-item">
      <CategoryLink to={post?.primaryRoute?.path}>
        {post?.images && post?.images?.length > 0 && (
          <Image
            className="post-image"
            sizes={400}
            src={post?.images.length > 0 && post?.images[4]?.url}
            aspect="4:5"
            cover
          />
        )}
        <CategoryContent>
          <h2>{post.name}</h2>
          <CategoryHiddenContent>
            <span
              dangerouslySetInnerHTML={{
                __html: content
              }}
            />
            <CallToAction>{t('Read more')}</CallToAction>
          </CategoryHiddenContent>
        </CategoryContent>
      </CategoryLink>
    </CategoryItem>
  );
};

export default BlogCard;
