import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown, faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from 'linaria/react';
import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import BlogLogo from '../../img/blogLogo.png';
import { theme } from '../Theme';
import BlogQuery from './BlogQuery.gql';
library.add(faAngleRight, faAngleDown);

const Sidebar = styled('aside')`
  background: #f7f7f7;
  margin-bottom: 5rem;
  height: 100%;
  ${theme.below.md} {
    margin-bottom: 1rem;
  }
  a {
    color: #000;
    text-decoration: none;
    transition: all ease 0.3s;
    &:hover {
      color: ${theme.colors.secondaryblue};
      transition: all ease 0.3s;
    }
    &.active {
      font-weight: bold;
    }
  }
  ul {
    margin-left: 0;
    font-size: 80%;
    + ul {
      padding-left: 1rem;
    }
  }
`;

const MainCat = styled('div')`
  li {
    border-top: 1px solid ${theme.colors.lightgrey};
    display: flex;
    align-content: center;
    justify-content: space-between;
    font-weight: bold;
    padding: 0.5rem 0;
    text-transform: uppercase;
    span:first-of-type {
      display: block;
      padding-top: 6px;
    }
  }
`;

const SubCats = styled('li')`
  :last-of-type {
    margin-bottom: 0.75rem;
  }
`;

const StyledLogo = styled('div')`
  width: 100%;
  background: ${theme.colors.secondaryblue};
  a {
    display: block;
    text-align: center;
    padding: 40px 10px;
    img {
      width: 200px;
      margin: auto;
    }
  }
`;

const IndicatorIcon = styled('span')`
  margin-left: auto;
  font-size: 1rem;
  padding-right: 1px;
  width: 30px;
  text-align: right;
  svg {
    height: 16px;
  }
`;
const Content = styled('div')`
  padding: 0 30px 30px 30px;
`;

const AccordionIndicator = ({ isOpen }) => (
  <IndicatorIcon>
    {isOpen ? (
      <FontAwesomeIcon icon={['fal', 'angle-down']} />
    ) : (
      <FontAwesomeIcon icon={['fal', 'angle-right']} />
    )}
  </IndicatorIcon>
);

const Accordion = ({ children, single }) => {
  const [openIndexes, setOpenIndexes] = useState([]);

  const handleClick = index => {
    if (single) {
      setOpenIndexes(openIndexes.includes(index) ? [] : [index]);
    } else {
      setOpenIndexes(
        openIndexes.includes(index)
          ? openIndexes.filter(i => i !== index)
          : [...openIndexes, index]
      );
    }
  };

  const AccordionContent = ({ children, isOpen }) => {
    if (!isOpen) return null;
    return children;
  };

  return children({ openIndexes, handleClick, AccordionContent });
};

const BlogSidebar = ({ path, pathname }) => {
  const { data, loading, error } = useQuery(BlogQuery, {
    variables: {
      pathname: path
    }
  });

  if (error) return null;
  if (loading) return null;

  const {
    route: { object }
  } = data;

  return (
    <Sidebar>
      <StyledLogo>
        <Link to="/blog">
          <img src={BlogLogo} alt="Finntack Blog" />
        </Link>
      </StyledLogo>
      <Content>
        <Accordion single>
          {({ openIndexes, handleClick, AccordionContent }) => (
            <>
              {object?.subcategories?.map((category, index) => (
                <div key={index}>
                  <ul>
                    <Link
                      to={category?.primaryRoute?.path}
                      onClick={() => {
                        handleClick(index);
                      }}
                    >
                      <MainCat>
                        <li>
                          <span>{category?.name}</span>
                          <AccordionIndicator
                            isOpen={
                              openIndexes.includes(index) ||
                              pathname.indexOf(category?.primaryRoute?.path) >
                                -1
                            }
                          />
                        </li>
                      </MainCat>
                    </Link>
                  </ul>
                  {category?.subcategories?.length > 0 && (
                    <AccordionContent
                      isOpen={
                        openIndexes.includes(index) ||
                        pathname.indexOf(category?.primaryRoute?.path) > -1
                      }
                    >
                      <ul>
                        {category?.subcategories?.map((subCat, subIndex) => (
                          <SubCats key={subIndex}>
                            <Link
                              className={
                                subCat?.primaryRoute?.path === pathname
                                  ? 'active'
                                  : ''
                              }
                              to={subCat?.primaryRoute?.path}
                            >
                              {subCat?.name}
                            </Link>
                          </SubCats>
                        ))}
                      </ul>
                    </AccordionContent>
                  )}
                </div>
              ))}
            </>
          )}
        </Accordion>
      </Content>
    </Sidebar>
  );
};

export default BlogSidebar;
